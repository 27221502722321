import React, { useContext, useState } from 'react';
import { Spin } from 'antd';

export type AuthContextData = {
  loading: boolean;
  setLoading: (value: boolean) => void;
};
const AuthContext = React.createContext<AuthContextData>({} as any);

export const AuthProvider = AuthContext.Provider;
export default AuthContext;
export const AuthContextWrapper = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <AuthProvider value={{ loading, setLoading }}>
      <Spin spinning={loading}>{props.children}</Spin>
    </AuthProvider>
  );
};
export const useAuth = () => {
  const context = useContext(AuthContext);
  return context!;
};
