import LoginPage from '../page-components/auth/LoginPage';
import { NextPageContext } from 'next';
import { AUTH_TOKEN } from '../connection/Connection';
import * as cookie from 'cookie';

export default LoginPage;
export const getServerSideProps = async (context: NextPageContext) => {
  const parsedCookies = cookie.parse(context.req?.headers?.cookie ?? '');
  if (parsedCookies[AUTH_TOKEN]) {
    return {
      redirect: {
        destination: '/switch-user',
        permanent: false,
      },
    };
  }
  return {
    props: { email: context?.query?.email ?? null },
  };
};
