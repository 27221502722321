import { Button, Checkbox, Divider, Row, Space, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Link from 'next/link';
import React, { FC, useCallback, useState } from 'react';
import { Form, FormSchema, SchemaFormItems } from '../../components/form/Form';
import { useConnection } from '../../connection/Application';
import styles from './signup.module.less';
import AuthLayout from './AuthLayout';
import { SocialLoginButtons } from './SocialLoginButtons';
import Router, { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { DEFAULT_OG_IMAGES, SEO_COMMON } from '../../layout/candidate/CandidateLayout';
import Utils from '../../utils/Utils';
import config from '../../config';

const { Title } = Typography;

const schema: FormSchema = [
  {
    id: 'email',
    type: 'string',
    name: 'Email Address',
    props: { placeholder: 'example@email.com' },
    options: {
      rules: [
        { required: true, message: 'Email is required' },
        { type: 'email', message: 'Invalid Email' },
      ],
    },
  },
  {
    id: 'password',
    type: 'password',
    name: 'Password',
    props: { placeholder: 'Enter your password' },
    options: {
      rules: [{ required: true, message: 'Password is required' }],
    },
  },
];

const LoginPage: FC<{ email?: string }> = ({ email }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const history = useRouter();
  const connection = useConnection();
  const [remember, setRemember] = useState<boolean>(false);

  const onSubmit = useCallback(
    (formData) => {
      setLoading(true);
      connection
        .post('auth/login', { email: formData.email, password: formData.password })
        .then((response) => {
          const user = connection.setToken(
            response.token.accessToken,
            response.token.refreshToken,
            remember,
          );
          Utils.sendGtmCustomEvent(
            'login',
            {
              loginMethod: 'email',
            },
            {
              userId: user?.userId ?? 'guest',
              envId: config.envId,
            },
          );
          if (localStorage.getItem('BackUrl')) {
            Router.push({
              pathname: localStorage.getItem('BackUrl') ?? '',
              query: {
                ...JSON.parse(localStorage.getItem('BackQuery') ?? '{}'),
              },
            });
            localStorage.removeItem('BackUrl');
            localStorage.removeItem('BackQuery');
          } else {
            history.push('/');
          }
        })
        .catch((error) => {
          const body = error.body?.body;
          if (body?.status === 'pending') {
            history.push(`/verification-sent/${body.email}`);
          }
          form.setFields([
            { name: 'email', errors: [error.body.message] },
            { name: 'password', errors: [' '] },
          ]);
        })
        .finally(() => setLoading(false));
    },
    [connection, form, history, remember],
  );

  return (
    <React.Fragment>
      <NextSeo
        canonical="https://www.careers360.lk/login"
        title="Sign In | Careers360"
        description="Careers360 business focuses on giving our recruiters and candidates an innovative technology-driven top job portal in Sri Lanka."
        additionalMetaTags={[{ name: 'keywords', content: 'Recruiter| Careers360 | top job' }]}
        openGraph={{
          title: `Sign In | Careers360`,
          description:
            'Careers360 business focuses on giving our recruiters and candidates an innovative technology-driven top job portal in Sri Lanka.',
          images: DEFAULT_OG_IMAGES,
        }}
        {...SEO_COMMON}
      />
      <AuthLayout>
        <Title level={3} className={styles.title}>
          Sign In
        </Title>
        <Typography className={styles.subTitle} style={{ marginBottom: 20 }}>
          Welcome back! Please sign in to continue.
        </Typography>
        <SocialLoginButtons />
        <Divider plain>
          <Typography style={{ color: '#6C6C6C', fontSize: 14 }}>or Sign In</Typography>
        </Divider>
        {/*<Space style={{ marginTop: 10 }}>*/}
        {/*  <Typography style={{ color: '#5B6272', fontSize: 14 }}>Don’t have an account?</Typography>*/}
        {/*  <Link href={'/sign-up'}>*/}
        {/*    <Typography*/}
        {/*      style={{ color: '#009E52', fontSize: 14, fontWeight: 'bold', cursor: 'pointer' }}>*/}
        {/*      Sign Up*/}
        {/*    </Typography>*/}
        {/*  </Link>*/}
        {/*</Space>*/}
        <Form
          form={form}
          initialValues={{ email }}
          onFinish={onSubmit}
          layout="vertical"
          style={{ marginTop: 34 }}>
          <SchemaFormItems schema={schema} />

          <Row style={{ justifyContent: 'space-between' }}>
            <Checkbox
              style={{ color: '#5B6272' }}
              onChange={(event) => setRemember(event.target.checked)}
              checked={remember}>
              <Typography style={{ color: '#5B6272', fontSize: 14 }}>Remember me</Typography>
            </Checkbox>
            <Link href={'/reset-password'} id={'sign-in-link-forget-password'}>
              <Typography className={styles.forgetPassword}>Forgot Password?</Typography>
            </Link>
          </Row>
          <Button
            style={{ marginTop: 40, fontSize: 20 }}
            htmlType="submit"
            type="primary"
            block
            id={'sign-in-btn-sign-in'}
            loading={loading}>
            Sign In
          </Button>
        </Form>
        <div className={styles.signupcontainer}>
          <Space style={{ marginTop: 10 }}>
            <Typography style={{ color: '#5B6272', fontSize: 14 }}>
              Don’t have an account?
            </Typography>
            <Link href={'/sign-up'} id={'sign-in-link-sign-up'}>
              <Typography className={styles.signup}>Sign Up</Typography>
            </Link>
          </Space>
        </div>
      </AuthLayout>
    </React.Fragment>
  );
};

export default LoginPage;
