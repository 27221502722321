import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { useConnection } from '../../connection/Application';
import Image from 'next/image';
import google from '../../public/google.png';
import Router, { useRouter } from 'next/router';
import Utils from '../../utils/Utils';
import { useCookies } from 'react-cookie';
import config from '../../config';

// export const GoogleAuthButton: FC = () => {
//   const connection = useConnection();
//   const router = useRouter();
//   const { setLoading } = useAuth();
//   const googleAuth = useCallback(() => {
//     setLoading(true);
//     Utils.sendGtmCustomEvent('link_click', { click_text: 'Google Auth' });
//     connection
//       .get('auth/google')
//       .then((res) => {
//         router.push(res.url);
//       })
//       .catch(() => console.log('Error in Signin with google'))
//       .finally(() => {
//         setLoading(false);
//       });
//   }, [connection, router, setLoading]);
//
//   return (
//     <Button
//       className="socialauth"
//       shape="circle"
//       onClick={googleAuth}
//       style={{
//         display: 'flex',
//         alignItems: 'center',
//         justifyContent: 'center',
//         width: 58,
//         height: 58,
//         background: '#F6F6F6',
//       }}>
//       <Image src={google} width={30} height={30} alt={'Google Logo'} />
//     </Button>
//   );
// };

export const GoogleAuthButton: FC = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookie, setCookie, removeCookie] = useCookies(['referral']);
  const connection = useConnection();
  const { setToken } = connection;

  const callBackend = useCallback(
    (code) => {
      connection
        .post('auth/google', {
          code,
          referralId: cookie.referral?.referralId,
          campaign: Utils.getCampaignDataFromUtm(),
        })
        .then((res) => {
          const user = setToken(res.token.accessToken, res.token.refreshToken, true);
          Utils.sendGtmCustomEvent(
            res.token.signup ? 'signup' : 'login',
            {
              loginMethod: 'google',
            },
            {
              userId: user?.userId ?? 'guest',
              envId: config.envId,
            },
          );
          if (localStorage.getItem('BackUrl')) {
            Router.push({
              pathname: localStorage.getItem('BackUrl') ?? '',
              query: {
                ...JSON.parse(localStorage.getItem('BackQuery') ?? '{}'),
              },
            });
            localStorage.removeItem('BackUrl');
            localStorage.removeItem('BackQuery');
          } else {
            router.replace('/');
          }
        })
        .catch((e: any) => {
          message.error(e.body?.message);
          router.replace('/login');
        })
        .finally(() => {
          if (cookie.referral?.referralId) {
            removeCookie('referral', {
              path: '/',
              sameSite: true,
            });
          }
        });
    },
    [connection, cookie.referral?.referralId, removeCookie, router, setToken],
  );

  useEffect(() => {
    if (typeof window === 'undefined' || !window.google || !divRef.current) {
      return;
    }

    try {
      window.google?.accounts.id.initialize({
        client_id: '752881697648-a22gnhrm2lhtkgrdju4aed9tsk7grner.apps.googleusercontent.com',
        callback: async (res: any) => {
          callBackend(res.credential);
        },
      });
      window.google.accounts.id.renderButton(divRef.current, {
        type: 'icon',
        theme: 'outline',
        size: 'large',
        shape: 'circle',
      });
    } catch (error) {
      console.log({ error });
    }
  }, [callBackend]);

  return (
    <Button
      shape={'round'}
      ref={divRef}
      className="socialauth"
      id={'btn-google-login'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 58,
        height: 58,
        background: '#F6F6F6',
      }}>
      <Image src={google} width={30} height={30} alt={'Google Logo'} />
    </Button>
  );
};
