import React, { FC, useCallback } from 'react';
import { Button } from 'antd';
import { useConnection } from '../../connection/Application';
import Image from 'next/image';
import facebook from '../../public/facebook.png';
import { useRouter } from 'next/router';
import { useAuth } from '../../page-components/auth/AuthContext';
import Utils from '../../utils/Utils';

export const FacebookAuthButton: FC = () => {
  const connection = useConnection();
  const router = useRouter();
  const { setLoading } = useAuth();
  const facebookAuth = useCallback(() => {
    Utils.sendGtmCustomEvent('link_click', { click_text: 'Facebook Auth' });
    setLoading(true);
    connection
      .get('auth/facebook')
      .then((res) => {
        router.push(res.url);
      })
      .catch(() => console.log('Error in Login with Facebook'))
      .finally(() => setLoading(false));
  }, [connection, router, setLoading]);

  return (
    <Button
      className="socialauth"
      onClick={facebookAuth}
      shape="circle"
      id={'btn-facebook-in-login'}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 58,
        height: 58,
        background: '#F6F6F6',
      }}>
      <Image src={facebook} width={30} height={30} alt={'Facebook Logo'} />
    </Button>
  );
};
