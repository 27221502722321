import React, { FC, PropsWithChildren } from 'react';
import logo from '../../public/logo.svg';
import Image from 'next/image';
import styles from './signup.module.less';
import { useRouter } from 'next/router';
import { AuthContextWrapper } from './AuthContext';

const AuthLayout: FC<PropsWithChildren> = (props) => {
  // const user = useUserInfo();
  const router = useRouter();
  // useEffect(() => {
  //   if (user) {
  //     router.push('/');
  //   }
  // }, [router, user]);
  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.logo}>
          <Image
            onClick={() => router.replace('/')}
            src={logo}
            width={220}
            height={43}
            alt={'Careers360 Logo'}
          />
        </div>
        <div className={styles.formArea}>
          <AuthContextWrapper>{props.children}</AuthContextWrapper>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AuthLayout;
