import React, { FC } from 'react';
import { LinkedinAuthButton } from '../../components/AuthButtons/linkedInAuthButton';
import { FacebookAuthButton } from '../../components/AuthButtons/facebookAuthButton';
import { GoogleAuthButton } from '../../components/AuthButtons/googleAuthButton';
import { FlexBox } from '../../components/FlexBox';
import styles from './signup.module.less';

export const SocialLoginButtons: FC = () => {
  return (
    <FlexBox horizontalAlign={'center'}>
      <div className={styles.socialBtn}>
        <LinkedinAuthButton />
        <FacebookAuthButton />
        <GoogleAuthButton />
      </div>
    </FlexBox>
  );
};
